import React, { useRef, useState } from 'react';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { PaymentPageProps } from '../../../typesAndInterfaces/componentProps';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import MB_MobilePurchase from '../../../mightyByteLibraries/MB_MobilePurchase';
import { mbHidePopUp, mbShowPopUp, MB_PopUpTemplateComponents } from '@mightybyte/rnw.components.pop-up';
import { CONSTANTS, STRING_CONSTANTS } from '../../../constants/constants';
import { useCheckPaymentStatus } from '../../../hooks/paymentAndOrdersHooks';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { PaymentPageEventResponder } from './PaymentPageEventResponder';
import { PAYMENT_STATUS } from '../../../typesAndInterfaces/typesAndInterfaces';
import { usePaymentContext } from '../../../context/PaymentContextProvider';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import Feather from 'react-native-vector-icons/Feather';
import { useAppyEducatorCode } from '../../../hooks/userHooks';

const PaymentPage = ({ navigation }: PaymentPageProps) => {
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true, pageTitle: 'Payment' });
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const { mutate: checkPaymentStatus, isLoading: isCheckPurchaseStatusLoading } = useCheckPaymentStatus();

    const { paymentStatus } = usePaymentContext();
    const { mutate: applyEducatorCode, isLoading: isApplyingCode, data: educatorCode } = useAppyEducatorCode();

    const [errorMessage, setErrorMessage] = useState('');
    const [code, setCode] = useState('');

    const onDismissPopUpPaymentStatusModal = (shouldNavigateBack: boolean) => {
        setPaymentInProgress(false);
        if (shouldNavigateBack) {
            navigation.pop();
        }

    };

    async function validateProductId(productId: string) {
        try {
            const products = await MB_MobilePurchase.getProducts([productId]);
            if (products.length < 1) {
                console.log('Error: Failed to find store listing with given product id of: ' + productId);
                mbShowPopUp({
                    title: 'We are sorry',
                    message: 'This product is not available',
                    topComponent: MB_PopUpTemplateComponents.components.exclamationCircle,
                });
                return undefined;
            }

            return productId;
        } catch (error) {
            console.error('Error when validating product ID: ', error);
        }
    }

    async function onPayPressed() {
        setPaymentInProgress(true);

        const validProductId = await validateProductId(CONSTANTS.PREMIUM_PURCHASE_NAME);

        if (!validProductId) {
            mbShowPopUp({
                title: 'Failed to start payment process',
                message: 'Please check your internet connection and make sure you are logged in to your Apple/Google account. \n\n Then, restart the app and try again.',
                topComponent: MB_PopUpTemplateComponents.components.exclamationCircle,
            });
            return;
        }

        checkPaymentStatus(undefined, {
            onSuccess: async (newPaymentStatus) => {
                if (newPaymentStatus === PAYMENT_STATUS.pending || newPaymentStatus === PAYMENT_STATUS.paid) {
                    mbShowPopUp({
                        title: STRING_CONSTANTS.ERROR,
                        message: newPaymentStatus === PAYMENT_STATUS.pending ? 'Your previous purchase is still in processing' : 'You already purchased this product',
                        buttonAction: () => {
                            setPaymentInProgress(false);
                            mbHidePopUp();
                        },
                    });
                    return;
                }

                await MB_MobilePurchase.requestPurchase({ sku: validProductId });
            },
            onError: (error) => {
                console.error('Error when checking purchase status', error);
                mbShowPopUp({
                    title: 'Failed to check payment status',
                    message: 'We were unable to idendify your payment status. \n\n Please check your internet connection and make sure you are logged in to your Apple/Google account. \n\n Then, restart the app and try again.',
                    topComponent: MB_PopUpTemplateComponents.components.exclamationCircle,
                });
            },
        });
    }

    const onApplyEducatorCode = () => {
        applyEducatorCode({ code }, {
            onError: (error: any) => setErrorMessage(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
        });
    };

    // if (Platform.OS === 'web') {
    //     // TODO: Address this
    //     return (
    //         <ComponentWrapper
    //             onBackPress={() => navigation.navigate('HomeNavigator', { screen: 'GameNavigator', params: { screen: 'SelectGame' } })}
    //             mobileHeaderOptions={mobileHeaderOptions.current} innerContainerStyle={{ flex: 1 }}>
    //             <View style={{ flex: 1, justifyContent: 'center' }}>
    //                 <Text style={mbTextStyles([textStyles.largestText, { marginTop: -100 }])}>Coming Soon</Text>
    //                 <Text style={mbTextStyles([textStyles.smallerText])}>Please proceed to pay on the mobile app to get premium access</Text>
    //             </View>
    //         </ComponentWrapper>
    //     );
    // }

    return (
        <ComponentWrapper mobileHeaderOptions={mobileHeaderOptions.current}>

            <PaymentPageEventResponder
                success={educatorCode !== undefined}
                onDismiss={onDismissPopUpPaymentStatusModal}
            />

            {Platform.OS !== 'web' &&
                <MB_LinearGradient
                    colors={[COLORS.backgroundPurple, COLORS.gradientLightPurple]}
                    start={{ x: 0.8, y: 0 }}
                    end={{ x: 1.5, y: 0 }}
                    style={styles.paymentContainer}
                >
                    <Text style={styles.paymentTitle}>One time payment</Text>
                    <Text style={styles.paymentPrice}>✨ $14.99 ✨</Text>
                </MB_LinearGradient>
            }

            <View style={styles.educatorCode}>
                <Text style={styles.educatorCodeText}>Have an educator code?</Text>
                <View style={styles.educatorCodeRow}>
                    <View style={styles.educatorCodeInputConatiner}>
                        <MB_TextInput
                            style={[styles.educatorCodeInput, Boolean(errorMessage) && styles.educatorCodeInputError]}
                            placeholder="Enter educator code"
                            placeholderTextColor="#C2C2C2"
                            value={code}
                            onChangeText={setCode}
                        />
                    </View>
                    <MB_Button
                        style={styles.educatorCodeButton}
                        rightElement={<Feather name="arrow-right" color="#FFFFFF" size={25} />}
                        rightElementStyle={{ marginLeft: -2 }}
                        disabled={isApplyingCode || !code}
                        loading={isApplyingCode}
                        onPress={onApplyEducatorCode}
                    />
                </View>
                <Text style={styles.errorMessageText}>{errorMessage ?? ' '}</Text>
            </View>
            {Platform.OS !== 'web' &&

                <View style={{ marginTop: 'auto' }}>
                    {paymentStatus === PAYMENT_STATUS.pending &&
                        <Text style={textStyles.popUpErrorMessageText}>Warning: Previous payment is still in processing, please wait.</Text>
                    }

                    <MB_Button
                        onPress={onPayPressed}
                        title="Pay Now"
                        style={styles.buttonContainer}
                        textStyle={styles.buttonText}
                        disabled={isCheckPurchaseStatusLoading || paymentInProgress || paymentStatus === PAYMENT_STATUS.pending || paymentStatus === PAYMENT_STATUS.paid}
                        loading={(isCheckPurchaseStatusLoading || paymentInProgress) && paymentStatus !== PAYMENT_STATUS.pending && paymentStatus !== PAYMENT_STATUS.paid}
                    />
                </View>
            }
        </ComponentWrapper >
    );
};

export default PaymentPage;

const styles = StyleSheet.create({
    buttonContainer: {
        width: '80%',
        alignSelf: 'center',
        backgroundColor: COLORS.gradientDarkPurple,
        marginTop: 16,
    },
    paymentContainer: {
        marginTop: 16,
        borderRadius: 16,
        padding: 16,
    },
    paymentTitle: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
            fontWeight: '600',
        },
    ]),
    paymentPrice: mbTextStyles([
        textStyles.largeText, {
            // fontSize: 16,
            textAlign: 'left',
            fontWeight: '600',
        },
    ]),
    payNowContainer: {
        borderRadius: 12,
        paddingVertical: 16,
    },
    buttonText: mbTextStyles([
        textStyles.normalText, {
            fontSize: 16,
            textAlign: 'center',
            fontWeight: '600',
        },
    ]),

    // educatorCode

    educatorCode: {
        borderWidth: 1,
        borderColor: '#412366',
        padding: 10,
        borderRadius: 7,
        marginTop: 15,
    },
    educatorCodeText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
    }]),
    educatorCodeRow: {
        flexDirection: 'row',
        marginTop: 12,
        alignItems: 'center',
    },
    educatorCodeInputConatiner: {
        flex: 1,
    },
    educatorCodeInput: {
        flexGrow: 1,
        width: 'auto',
        alignSelf: 'stretch',
        height: 48,
        backgroundColor: '#220133',
        borderColor: '#220133',
        borderWidth: 1,
    },
    educatorCodeInputError: {
        borderColor: '#E53232',
    },
    educatorCodeButton: {
        width: 54,
        height: 48,
        backgroundColor: '#A430FF',
        borderRadius: 10,
        marginLeft: 5,
    },
    errorMessageText: mbTextStyles([textStyles.smallerText, {
        marginTop: 8,
        textAlign: 'left',
        fontWeight: '500',
        color: '#E53232',
    }]),
});
