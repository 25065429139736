import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { Token } from '@mightybyte/rnw.utils.access-token-utils';
import { userApiCalls } from '../apiCalls/userApiCalls';
import { QUESTION_QUERY_KEYS, USER_QUERY_KEYS } from '../constants/constants';
import { useSignedInContext } from '../context/SignedInContext';
import {
  CategoryForUser,
  ChangeDifficultyResponse,
  DIFFICULTY, EducatorCode, GAME_TYPE, ServerError, User,
} from '../typesAndInterfaces/typesAndInterfaces';
import { MB_InfinitePaginationPage } from '@mightybyte/rnw.utils.util-hooks';


export const useGetCurrentUserData = (queryOptions?: UseQueryOptions<User, ServerError>) => {
  const { setCurrentUserData } = useSignedInContext();

  return useQuery<User, ServerError>(
    [USER_QUERY_KEYS.getCurrentUsertData],
    async () => {
      const currentUserData = await userApiCalls.getCurrentUserData();
      setCurrentUserData(currentUserData);
      return currentUserData;
    },
    { staleTime: Infinity, ...queryOptions });
};

export const useAnswerUserQuestionaire = () => {
  const { setCurrentUserData } = useSignedInContext();
  const queryClient = useQueryClient();

  return useMutation<User, ServerError, { isUserDeaf: boolean; isUserKnowDeaf: boolean, interestedSchool: boolean, interestedWork: boolean, interestedPersonal: boolean }, unknown>(
    async (data) => userApiCalls.answerUserQuestionaire(data), {
    onSuccess: (user) => {
      queryClient.setQueriesData([USER_QUERY_KEYS.getCurrentUsertData], user);
      setCurrentUserData(user);
    },
  }
  );
};

export const useSignIn = () => {
  return useMutation<Token, ServerError, { email: string; password: string }, unknown>(
    async (data) => userApiCalls.login(data.email, data.password));
};

export const useSignUp = () => {
  return useMutation<Token, ServerError, { email: string, firstName: string, lastName: string, password: string, signUpCode: string }, unknown>(
    async (data) => userApiCalls.signUp(data.email, data.firstName, data.lastName, data.password, data.signUpCode)
  );
};

export const useRequestSignUpCode = () => {
  return useMutation<void, ServerError, { email: string }, unknown>(
    async (data) => userApiCalls.requestSignUpCode(data.email)
  );
};

export const useResetPasswordRequest = () => {
  return useMutation<void, ServerError, string, unknown>(
    async (data) => userApiCalls.resetPasswordRequest(data)
  );
};

export const useChangePassword = () => {
  return useMutation<void, ServerError, { currentPassword: string, newPassword: string }, unknown>(
    async (data) => userApiCalls.changePassword(data.currentPassword, data.newPassword));
};

export const useChangePasswordRecovery = () => {
  return useMutation<void, ServerError, { newPassword: string, recoveryToken: string }, unknown>(
    async (data) => userApiCalls.changePasswordRecovery(data.newPassword, data.recoveryToken));
};

export const useDeleteAccount = () => {
  return useMutation<void, ServerError>(
    async () => userApiCalls.deleteAccount(),
  );
};

export const useChangeUserDifficulty = () => {
  const queryClient = useQueryClient();

  return useMutation<ChangeDifficultyResponse, ServerError, { difficulty: DIFFICULTY }, unknown>(
    async (data) => userApiCalls.changeUserDifficulty(data.difficulty), {
    onSuccess: (incomingData) => {
      queryClient.setQueryData<User | undefined>([USER_QUERY_KEYS.getCurrentUsertData], (oldUser) => {
        if (!oldUser || oldUser._id !== incomingData.userId) {
          return oldUser;
        }

        return { ...oldUser, difficulty: incomingData.difficulty };
      });
      queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUsertData]);
      queryClient.invalidateQueries([USER_QUERY_KEYS.getCategoryForHomePage]);
      queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getQuestion]);
      queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getIncompleteQuestions]);
    },
  });
};

export const useGetCategoriesForHomePage = ({ gameType, difficulty, totalItemsPerPage, queryOptions }: { gameType: GAME_TYPE, totalItemsPerPage: number, difficulty?: DIFFICULTY, queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<CategoryForUser>, any> }) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<CategoryForUser>, any>([USER_QUERY_KEYS.getCategoryForHomePage, gameType, difficulty],
    async ({ pageParam }: { pageParam?: string }) => userApiCalls.getCategoriesForHomePage(gameType, totalItemsPerPage, pageParam, difficulty), {
    refetchOnWindowFocus: false,
    getNextPageParam: lastPage => lastPage.nextOffset ?? undefined,
    ...queryOptions,
  }
  );
};

export const useUpdateUserName = () => {
  const queryClient = useQueryClient();

  return useMutation<User, ServerError, { firstName?: string, lastName?: string }, unknown>(
    async (data) => userApiCalls.updateCurrentUserName(data.firstName, data.lastName), {
    onSuccess: (incomingUserData) => {
      queryClient.setQueryData<User | undefined>([USER_QUERY_KEYS.getCurrentUsertData], (oldUser) => {
        if (!oldUser || oldUser._id !== incomingUserData._id) {
          return oldUser;
        }

        return { ...oldUser, ...incomingUserData };
      });
      queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUsertData]);
    },
  });
};

export const useQuestionCompleted = () => {
  return useMutation<void, ServerError, string, unknown>(
    async (questionId) => userApiCalls.questionCompleted(questionId)
  );
};

export const useResetQuestionCompletion = () => {
  return useMutation<void, ServerError, string, unknown>(
    async (categoryId) => userApiCalls.resetQuestionCompletion(categoryId)
  );
};

export const useAppyEducatorCode = () => {
  const queryClient = useQueryClient();

  return useMutation<EducatorCode, ServerError, { code: string }, unknown>(
      async ({ code }) => userApiCalls.useEducatorCode(code), {
      onSuccess: () => {
          queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUsertData]);
      },
  });
};
