import React, { useCallback, useState } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { EducatorCode } from '../../../typesAndInterfaces/typesAndInterfaces';
import { MB_TextInput, MB_TextInputInputType } from '@mightybyte/rnw.components.text-input';
import { StyleSheet, Text, View } from 'react-native';
import { MB_DatePicker } from '@mightybyte/rnw.components.date-picker';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { useCreateEducatorCode, useEditEducatorCode } from '../../../hooks/adminHooks';
import { STRING_CONSTANTS } from '../../../constants/constants';

function onlyUppercaseAndNumber(setValue: (value: string) => void) {
    return (value: string) => {
        setValue(value.toUpperCase().replace(/[^A-Z0-9]{1,}/g, ''));
    };
}

type EducatorCodeEditorProps = {
    isVisible: boolean,
    onDismiss?: () => void,
    educatorCode?: EducatorCode
};

function EducatorCodeEditor({ isVisible, onDismiss, educatorCode }: EducatorCodeEditorProps) {

    const { mutate: createEducatorCode, isLoading: isCreating } = useCreateEducatorCode();
    const { mutate: editEducatorCode, isLoading: isEditing } = useEditEducatorCode();

    const [errorMessage, setErrorMessage] = useState(' ');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [numberOfLicenses, setNumberOfLicenses] = useState('');
    const [expireDate, setExpireDate] = useState<Date>();
    const [description, setDescription] = useState('');

    const isDisabled = (!code || !name || !numberOfLicenses || !expireDate) || (
        code === educatorCode?.code &&
        name === educatorCode.name &&
        numberOfLicenses === educatorCode.numberOfLicenses.toString() &&
        expireDate.toISOString() === educatorCode.expireDate &&
        description === educatorCode.description
    );

    const onButtonPress = useCallback(() => {
        if (!code || !name || !numberOfLicenses || !expireDate) {
            setErrorMessage('Please fill all the required fields');
        } else if (educatorCode) {
            editEducatorCode({
                educatorCodeId: educatorCode._id,
                name,
                numberOfLicenses: Number(numberOfLicenses),
                expireDate: expireDate.toISOString(),
                description,
            }, {
                onSuccess: onDismiss,
                onError: (error: any) => setErrorMessage(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        } else {
            createEducatorCode({
                code,
                name,
                numberOfLicenses: Number(numberOfLicenses),
                expireDate: expireDate.toISOString(),
                description,
            }, {
                onSuccess: onDismiss,
                onError: (error: any) => setErrorMessage(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    }, [code, createEducatorCode, description, editEducatorCode, educatorCode, expireDate, name, numberOfLicenses, onDismiss]);

    const onModalWillShow = useCallback(() => {
        setErrorMessage('');
        setCode(educatorCode ? educatorCode.code : '');
        setName(educatorCode ? educatorCode.name : '');
        setNumberOfLicenses(educatorCode ? educatorCode.numberOfLicenses.toString() : '');
        setExpireDate(educatorCode ? new Date(educatorCode.expireDate) : undefined);
        setDescription(educatorCode ? educatorCode.description ?? '' : '');
    }, [educatorCode]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            title={`${educatorCode !== undefined ? 'Edit' : 'Create'} educator code`}
            childrenWrapperStyle={styles.container}
            onModalWillShow={onModalWillShow}
        >
            <View pointerEvents={educatorCode === undefined ? 'auto' : 'none'}>
                <MB_TextInput
                    title="Code "
                    placeholder="Enter Code"
                    titleStyle={styles.titleText}
                    showRequiredAsteriks={educatorCode === undefined}
                    showCharacterLimit={educatorCode === undefined}
                    maxLength={15}
                    style={[styles.textInput, { marginTop: 0 }, educatorCode && styles.disabled]}
                    value={code}
                    onChangeText={onlyUppercaseAndNumber(setCode)}
                    disable={educatorCode !== undefined}
                />
            </View>
            <MB_TextInput
                title="Name "
                placeholder="Enter Name"
                titleStyle={styles.titleText}
                showRequiredAsteriks
                maxLength={30}
                style={styles.textInput}
                value={name}
                onChangeText={setName}
            />
            <View style={styles.row}>
                <MB_TextInput
                    title="No. of licenses "
                    placeholder="Set no. of licences"
                    titleStyle={styles.titleText}
                    showRequiredAsteriks
                    inputType={MB_TextInputInputType.numbers}
                    style={styles.feildInRow}
                    value={numberOfLicenses}
                    onChangeText={setNumberOfLicenses}
                />
                <View>
                    <Text style={styles.titleText}>Expiry Date <Text style={{ color: '#dd3636' }}>*</Text></Text>
                    <MB_DatePicker
                        key={String(isVisible)}
                        style={styles.feildInRow}
                        initialDate={expireDate}
                        onDateChanged={setExpireDate}
                    />
                </View>
            </View>
            <MB_TextInput
                title="Description"
                titleStyle={styles.titleText}
                placeholder="Enter educator description or important notes"
                maxLength={30}
                multiline
                style={[styles.textInput, styles.textInputMultiline]}
                value={description}
                onChangeText={setDescription}
            />
            <Text style={styles.errorText}>{errorMessage}</Text>
            <MB_Button
                title={`${educatorCode !== undefined ? 'Edit' : 'Create'} code`}
                style={styles.button}
                loading={isCreating || isEditing}
                disabled={isCreating || isEditing || isDisabled}
                onPress={onButtonPress}
            />
        </MB_Modal>
    );
}

export { EducatorCodeEditor };

const styles = StyleSheet.create({
    container: {
        width: 440,
        backgroundColor: '#321B50',
    },
    textInput: {
        width: 370,
        marginTop: 25,
    },
    disabled: {
        backgroundColor: '#311B3D',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 25,
        width: 370,
    },
    feildInRow: {
        width: 180,
        backgroundColor: '#321B50',
    },
    textInputMultiline: {
        height: 75,
    },
    titleText: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        marginBottom: 8,
    }]),
    button: {
        marginTop: 10,
        width: 370,
        backgroundColor: '#A430FF',
    },
    errorText: mbTextStyles([textStyles.smallText, {
        color: '#dd3636',
        marginTop: 8,
    }]),
});
