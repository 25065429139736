import React, { useEffect, useState } from 'react';
import { Image, Platform, StyleSheet } from 'react-native';
import { MB_PopUp, MB_PopUpSetDefaultStyles } from '@mightybyte/rnw.components.pop-up';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { isMobile, isMobileApp, MB_deviceInfoInitialize } from '@mightybyte/rnw.utils.device-info';
import { MB_orientationLocker, MB_ORIENTATION_LOCK } from '@mightybyte/rnw.utils.orientation-locker';
import { mbPlatformStyle, mbTextStyles, mbTextStylesSetDefaultFontFamily } from '@mightybyte/rnw.utils.style-utils';
import { userApiCalls } from '../apiCalls/userApiCalls';
import { COLORS } from '../constants/colors';
import { FONT_STYLES, textStyles } from '../constants/textStyles';
import { fontLoader } from '../utils/fontLoader';
import { MB_Toast } from '@mightybyte/rnw.components.toast';
import { MB_ModalAttachGlobalChildren } from '@mightybyte/rnw.components.modal';
import { MB_ResourceUploaderSetDefaultStyles } from '@mightybyte/rnw.components.resource-uploader';
import { CustomToastConfig } from '../components/helperComponents/CustomToastConfig';
import { TextProps, TextInputProps, Text, TextInput } from 'react-native';
import { imageUtils } from '../utils/imageUtils';
import { prefetchUtilContextProviderState } from '../context/UtilContextProvider';

type WithDefaultProps<T> = T & {
    defaultProps?: Object
}

function disableFontScaling() {
    (Text as WithDefaultProps<TextProps>).defaultProps = { allowFontScaling: false };
    (TextInput as WithDefaultProps<TextInputProps>).defaultProps = { allowFontScaling: false };
}


async function getSvgContent(svgModule: any): Promise<string> {
    if (isMobileApp) {
        const sourceURI = Image.resolveAssetSource(svgModule).uri;
        if (Platform.OS === 'android' && !__DEV__) {
            const RNFS = require('react-native-fs');
            // readFileRes get the content of the
            // images (.png, .jpg, .jpeg, .bmp, .gif, .webp, .psd, .svg, .tiff) from the res/drawable folder
            // otherfiles will try to get them from res/raw
            // in our case the svgs are stored on [res/raw] so we tricked the lib :)
            return RNFS.readFileRes(`${sourceURI}._svg`);
        }
        return fetch(sourceURI).then(res => res.text());
    }
    return fetch(svgModule).then(res => res.text());
}

async function loadSvgs() {
    const readSvgFilesAsText = Object
        .keys(imageUtils.svgs)
        .map(async key => {
            const svgModule = imageUtils.svgs[key as keyof typeof imageUtils.svgs].resource;
            const svgText = await getSvgContent(svgModule);
            imageUtils.svgs[key as keyof typeof imageUtils.svgs].asText = svgText;
        });
    await Promise.all(readSvgFilesAsText);
}

function useAppInitialSetup() {
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        async function performInitialSetup() {

            disableFontScaling();

            await loadSvgs();
            if (Platform.OS === 'ios') {
                const { Cache } = require('../utils/cache');
                await Cache.load();
            }
            await MB_deviceInfoInitialize();
            await prefetchUtilContextProviderState();

            MB_orientationLocker.setDefaultOrientation(MB_ORIENTATION_LOCK.PORTRAIT);

            mbTextStylesSetDefaultFontFamily(FONT_STYLES.PoppinsBase);

            MB_accessTokenUtils.setAccessTokenRefreshEndpointFunction(userApiCalls.requestNewAccessToken);

            MB_PopUpSetDefaultStyles({
                defaultContainerStyle: styles.modalPopUpDefaultContainer,
                defaultTitleStyle: styles.modalPopUpDefaultTitle,
                defaultMessageStyle: styles.modalPopUpDefaultMessage,
                defaultPrimaryButtonStyle: styles.modalPopUpDefaultPrimaryButton,
                defaultPrimaryButtonTextStyle: styles.modalPopUpDefaultPrimaryButtonText,
                defaultSeconaryButtonStyle: styles.modalPopUpDefaultSecondaryButton,
                defaultSecondaryButtonTextStyle: styles.modalPopUpDefaultSecondaryButtonText,
                defaultButtonsContainerStyleModifier: styles.modalPopUpDefaultButtonsContainerModifier,
            });

            MB_ResourceUploaderSetDefaultStyles({
                wrapperStyle: styles.resourceUploaderDefaultContainer,
                uploadButtonStyle: styles.resourceUploaderButton,
                previewContainerStyle: styles.resourceUploaderPreviewContainer,
                previewImageBackgroundStyle: styles.resourceUploaderPreviewImageBackground,
            });

            try {
                await fontLoader();
            } catch (error) {
                console.error('Prepare stage failed', error);
            } finally {
                setIsDone(true);
            }
        }

        MB_ModalAttachGlobalChildren([
            <MB_PopUp key={'popUp'} />,
            <MB_Toast config={CustomToastConfig} key={'toast'} />,
        ]);

        if (!isDone) {
            performInitialSetup();
        }
    }, [isDone]);

    return isDone;
}


export { useAppInitialSetup };

const styles = StyleSheet.create({
    modalPopUpDefaultContainer: {
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 8,
        paddingTop: 32,
        paddingHorizontal: isMobile ? 18 : 76,
        paddingBottom: 32,
        marginHorizontal: 16,
    },
    modalPopUpDefaultTitle: mbTextStyles([
        textStyles.largeText, {
            fontWeight: '700',
            ...mbPlatformStyle({
                mobile: {
                    fontSize: 18,
                },
            }),
        },
    ]),
    modalPopUpDefaultMessage: mbTextStyles([
        textStyles.smallText, {
            marginTop: isMobile ? 10 : 16,
            color: COLORS.textLightPurple,
            ...mbPlatformStyle({
                mobile: {
                    fontSize: 14,
                },
            }),
        },
    ]),
    modalPopUpDefaultPrimaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        alignSelf: 'center',
        backgroundColor: COLORS.buttonPurple,
    },
    modalPopUpDefaultPrimaryButtonText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.white,
        },
    ]),
    modalPopUpDefaultSecondaryButtonText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.textPurple,
        },
    ]),
    modalPopUpDefaultSecondaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        backgroundColor: COLORS.white,
    },
    modalPopUpDefaultButtonsContainerModifier: {
        marginTop: 33,
    },
    resourceUploaderDefaultContainer: {
        backgroundColor: COLORS.backgroundPurple,
        paddingHorizontal: 36,
    },
    resourceUploaderPreviewContainer: {
        backgroundColor: COLORS.backgroundDarkPurple,
    },
    resourceUploaderButton: {
        backgroundColor: COLORS.buttonPurple,
        width: 268,
        height: 62,
        marginTop: 32,
    },
    resourceUploaderPreviewImageBackground: {
        borderRadius: 300,
        width: 82,
        height: 82,
        backgroundColor: COLORS.backgroundPurple,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
