import { Token } from '@mightybyte/rnw.utils.access-token-utils';
import { envs } from '../../env';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { CategoryForUser, ChangeDifficultyResponse, DIFFICULTY, EducatorCode, GAME_TYPE } from './../typesAndInterfaces/typesAndInterfaces';
import {
  Image, ServerError, User,
} from '../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../utils/utils';
import { CategoryForUserObjInfinitePagination, ChangeDifficultyResponseObj, EducatorCodeObj, ImageObj, TokensObj, UserObj } from '../utils/zod/zodObjects';
import { axiosCaller, customAxios } from './customAxios';
import { MB_InfinitePaginationPage } from '@mightybyte/rnw.utils.util-hooks';

const userApiCalls = {
  /**
   * Requests a new access token from a server
   * @param accessToken Access token for server validation
   * @param refreshToken Refresh token that is used to get a new access token
   * @param skipThrows If set to true, will skip all the throws, used in axiosConfig. Since we need to call this
   * when we get invalid access token or refresh token(Server needs this to potentially log some malicious activities)
   * setting this to true will prevent an infinite loop situation.
   * @returns promise containing the new access token
   */
  requestNewAccessToken: async (accessToken: string, refreshToken: string, skipThrows?: boolean): Promise<Token> => {
    return await customAxios
      .post(envs.SERVER_URL + '/api/users/token', {
        accessToken,
      },
        {
          headers: {
            'Authorization': refreshToken,
          },
        }
      )
      .then(function (response) {
        if (skipThrows) {
          return;
        }

        const tokenPair = response?.data?.data?.tokenPair;

        const validatorResponse = TokensObj.safeParse(tokenPair);
        if (!validatorResponse.success) {
          console.error('tokenPair was ', tokenPair, validatorResponse.error.issues);
          throw utils.createErrorObject('Data mismatch for request new access token', SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR);
        }
        return tokenPair;
      })
      .catch(function (error) {
        console.error('Error while requesting access code', error.response);
        if (!skipThrows) {
          if (error?.response?.data) {
            throw error?.response?.data as ServerError;
          }

          const customError: ServerError = {
            message: 'Unknown Error',
            errorCode: SERVER_ERROR_CODES.UNKNOWN_ERROR,
            status: 'error',
          };

          throw customError;
        }
      });
  },
  getCurrentUserData: async (): Promise<User> => {
    return await axiosCaller({
      path: '/api/users/current-user-data',
      method: 'get',
      responseDataKey: 'userData',
      responseValidatorObj: UserObj,
      responseFormatValidatorMessage: 'Data mismatch for current user data response',
      passToken: true,
    });
  },

  login: async (email: string, password: string): Promise<Token> => {
    return await axiosCaller({
      path: '/api/users/login',
      method: 'post',
      responseDataKey: 'tokenPair',
      responseValidatorObj: TokensObj,
      responseFormatValidatorMessage: 'Data mismatch for login response',
      data: { email, password },
    });
  },

  signUp: async (email: string, firstName: string, lastName: string, password: string, signUpCode: string): Promise<Token> => {
    return await axiosCaller({
      path: '/api/users/sign-up',
      method: 'post',
      responseValidatorObj: TokensObj,
      responseFormatValidatorMessage: 'Data mismatch for signup response',
      responseDataKey: 'tokenPair',
      data: { email, firstName, lastName, password, signUpCode },
    });
  },

  requestSignUpCode: async (email: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/request-sign-up-code',
      method: 'post',
      data: { email },
    });
  },

  resetPasswordRequest: async (email: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/request-password-recovery-email',
      method: 'get',
      params: { email },
    });
  },

  signOut: async (): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/sign-out',
      method: 'post',
      data: {},
      passToken: true,
    });
  },

  updateCurrentUserData: async (description?: string, occupation?: string): Promise<User> => {
    return await axiosCaller({
      path: '/api/users/update-current-user-data',
      method: 'post',
      data: { description, occupation },
      passToken: true,
      responseValidatorObj: UserObj,
      responseFormatValidatorMessage: 'Data mismatch for current user data response',
      responseDataKey: 'userData',
    });
  },

  changePassword: async (currentPassword: string, newPassword: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/change-password',
      method: 'post',
      data: { currentPassword, newPassword },
      passToken: true,
    });
  },

  changePasswordRecovery: async (newPassword: string, recoveryToken: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/change-password-recovery',
      method: 'post',
      data: { newPassword, recoveryToken },
    });
  },

  uploadProfilePhoto: async ({ formData, uploadProgress }: { formData: FormData, uploadProgress?: (percentCompleted: number) => void }): Promise<Image> => {
    return await axiosCaller({
      path: '/api/users/changeProfilePhoto',
      method: 'post',
      responseValidatorObj: ImageObj,
      responseFormatValidatorMessage: 'Data mismatch for upload profile photo response',
      passToken: true,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = !progressEvent.total ? 0 : Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadProgress?.(percentCompleted);
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteAccount: async (): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/delete-account',
      method: 'post',
      data: {},
      passToken: true,
    });
  },

  changeUserDifficulty: async (difficulty: DIFFICULTY): Promise<ChangeDifficultyResponse> => {
    return await axiosCaller({
      path: '/api/users/changeUserDifficulty',
      method: 'post',
      responseValidatorObj: ChangeDifficultyResponseObj,
      responseFormatValidatorMessage: 'Data mismatch for changing users difficulty response',
      data: {
        difficulty: difficulty,
      },
      passToken: true,
    });
  },

  getCategoriesForHomePage: async (gameType: GAME_TYPE, totalItemsPerPage: number, offset?: string, difficulty?: DIFFICULTY): Promise<MB_InfinitePaginationPage<CategoryForUser>> => {
    return await axiosCaller({
      path: '/api/categories/getCategoriesForHomePage',
      method: 'get',
      responseValidatorObj: CategoryForUserObjInfinitePagination,
      responseFormatValidatorMessage: 'Data mismatch for get home screen category data response',
      passToken: true,
      params: { totalItemsPerPage, offset, gameType, difficulty },
    });
  },

  updateCurrentUserName: async (firstName?: string, lastName?: string): Promise<User> => {
    return await axiosCaller({
      path: '/api/users/update-current-user-data',
      method: 'post',
      data: { firstName, lastName },
      passToken: true,
      responseValidatorObj: UserObj,
      responseFormatValidatorMessage: 'Data mismatch for current user name data response',
      responseDataKey: 'userData',
    });
  },

  questionCompleted: async (questionId: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/questionCompleted',
      method: 'post',
      passToken: true,
      data: { questionId },
    });
  },

  resetQuestionCompletion: async (categoryId: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/resetQuestionCompletion',
      method: 'post',
      passToken: true,
      data: { categoryId },
    });
  },

  answerUserQuestionaire: async (props: { isUserDeaf: boolean; isUserKnowDeaf: boolean, interestedSchool: boolean, interestedWork: boolean, interestedPersonal: boolean }): Promise<User> => {
    return await axiosCaller({
      path: '/api/users/answerUserQuestionaire',
      method: 'post',
      data: props,
      passToken: true,
      responseValidatorObj: UserObj,
      responseFormatValidatorMessage: 'Data mismatch for current user data response',
      responseDataKey: 'userData',
    });
  },

  useEducatorCode: async (code: string): Promise<EducatorCode> => {
    return await axiosCaller({
        path: '/api/users/useEducatorCode',
        method: 'post',
        responseValidatorObj: EducatorCodeObj,
        responseFormatValidatorMessage: 'Data mismatch for use educator code data response',
        responseDataKey: 'educatorCode',
        passToken: true,
        data: { code },
    });
},
};

export { userApiCalls };
