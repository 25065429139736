import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface IUtilContext {
    dismissedPremiumBanner: boolean,
    setDismissedPremiumBanner: (dismissedPremiumBanner: boolean) => void,
}

const UtilContext = createContext<IUtilContext | undefined>(undefined);

const UtilContextProviderState = {
    dismissedPremiumBanner: false,
};

const prefetchUtilContextProviderState = async () => {
    const value = await AsyncStorage.getItem(UtilContextProvider.name);
    if (value) {
        try {
            const state = JSON.parse(value) as typeof UtilContextProviderState;
            UtilContextProviderState.dismissedPremiumBanner = state.dismissedPremiumBanner;
        } catch (error) {
            console.log('[UtilContextProvider] unknow storage state');
        }
    }
};

const resetUtilContextProviderState = async () => {
    await AsyncStorage.removeItem(UtilContextProvider.name);
};

const UtilContextProvider = ({ children }: { children?: ReactNode }) => {
    const [dismissedPremiumBanner, setDismissedPremiumBanner] = useState(UtilContextProviderState.dismissedPremiumBanner);

    const onDismissPremiumBanner = useCallback((value: boolean) => {
        setDismissedPremiumBanner(value);
        AsyncStorage.setItem(UtilContextProvider.name, JSON.stringify({
            dismissedPremiumBanner: value,
        }));
    }, []);

    const value = {
        dismissedPremiumBanner,
        setDismissedPremiumBanner: onDismissPremiumBanner,
    };

    return (
        <UtilContext.Provider value={value}>
            {children}
        </UtilContext.Provider>
    );
};

function useUtilContext() {
    const context = useContext(UtilContext);

    if (context === undefined) {
        throw new Error('useUtilContext must be used within a UtilContextProvider');
    }

    return context;
}

export { useUtilContext, UtilContext, UtilContextProvider, prefetchUtilContextProviderState, resetUtilContextProviderState };
